<template>
  <div class="mall">
    <van-grid :border="false" :column-num="3">
      <van-grid-item
        v-for="(item,index) in CateList"
        :key="'n'+index"
        @click="changecate(item)"
        :class="[cateid==item.id?'category-item current':'category-item']">
        <p class="text" :to="{path:'/Mall',query:{active:5,categoryid:item.id}}">{{item.category}}</p>
      </van-grid-item>
    </van-grid>
  </div>
</template>
  <script>
export default {
  name: "MallCate",
  data() {
    return {
      cateid: this.$props["categoryid"],
      CateList: []
    };
  },
  props: {
    categoryid: String
  },
  watch: {
    categoryid(newValue) {
      this.cateid = newValue;
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      var that = this;
      var params = {};
      that.$api.getMallcate(params).then(res => {
        that.CateList = res.data;
      });
    },
    changecate(item){
       this.cateid=item.id;
       this.$emit('change-category', item.id);
       this.$router.push({path:'/Mall',query:{active:5,categoryid:item.id}})
    }
  }
};
</script>
<style lang="less" scoped="">
.mall {
  margin: 5px 0;
  padding: 0 .5rem;
}
.title {
  margin-top: 20px;
}
.title img {
  width: 50%;
}

/deep/.van-grid-item .van-grid-item__content {
  border-radius: 1rem;
  border: 0.1rem solid #b70903;
  padding: 1rem;
}
/deep/.current .van-grid-item__content {
  border-radius: 1rem;
  border: 0.1rem solid #b70903;
  padding: 1rem;
  background: #b70903;
}

.category-item {
  background: #fff;
  padding: 1rem;
}

.category-item .text {
  margin: 0;
  padding: 0;
  font-size: 2.2rem;
  color: #b70903;
}
.current .text {
  color: #fff;
}
</style>